<template>
  <v-container :style="{display: 'flex', height: '100%', 'justify-content': 'center', margin: '0 auto 0 auto'}" class="hideScroll">
    <v-card flat width="600" style="align-self: center; padding: 20px;">
      <v-img v-if="!$vuetify.theme.dark" height="100" src="@/assets/tiimi_logo_bonw.png"></v-img>
      <v-img v-if="$vuetify.theme.dark" src="@/assets/svg/darklogo.svg" contain height="90" style="margin-block: 1em"></v-img>
      <v-card-title style="text-align: center; justify-content: center;">
        {{$t('loginflow.login_to_tiimi')}}
      </v-card-title>
      <v-form 
        ref="form" 
        @submit="submit"
        :lazy-validation="true"
      >
        <v-text-field
          ref="username"
          autocomplete="username"
          solo
          rounded
          type="email"
          v-model="form.email"
          placeholder="email"
          :rules="required"
        ></v-text-field>
        <v-text-field
          ref="password"
          autocomplete="password"
          solo
          rounded
          :type="passwordType ? 'text' : 'password'"
          :append-icon="passwordType ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="passwordType = !passwordType"
          placeholder="password"
          v-model="form.password"
          :rules="required"
        ></v-text-field>
        <div class="my-1" style="text-align: center;">
          <v-btn x-small text @click="$router.push('/forgot')">{{$t('loginflow.order_new')}}</v-btn>
          <v-btn x-small text @click="$router.push('/signin')">{{$t('loginflow.signin_here')}}</v-btn>
        </div>
        <v-card-actions style="justify-content: center;">
          <v-btn rounded class="mt-2" :loading="loading" type="submit" color="primary">
            {{$t('login')}}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data: () => ({
    form: {
      email: '',
      password: ''
    },
    passwordType: false,
    loading: false,
    email: '',
    required: [
      v => !!v || 'Required',
    ]
  }),
  methods: {
    ...mapActions({
      login: 'auth/login',
      error: 'noti/error'
    }),
    ...mapActions('user', [
      'initUser'
    ]),
    submit(e) {
      e.preventDefault();
      
      const validated = this.$refs.form.validate()
      if(!validated) return

      this.loading = true
      this.login(this.form)
        .then(res => {
          this.initUser(res.data.token)
          if(this.$route.query.invite_code) {
            this.$router.push({ path: '/team/join', query: this.$route.query })
          } else if(this.$route.query.ref) {
            this.$router.push(this.$route.query.ref)
          } else {
            this.$router.push('/videos')
          }

          this.track('Login')
        })
        .catch(err => {
          this.error(err)
          this.track('Login error', {
            error: JSON.stringify(err)
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>